import React from "react";
import "./App.css";
import gitarrenEffekteLogo from "./gitarren-effekte.png";
import loopstationLogo from "./loopstation.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>SoundCrunch</h1>

        <div className="website-container">
          <img src={loopstationLogo} alt="" />
          <div>
            <a href="https://www.loopstation.net/">loopstation.net</a>
          </div>
        </div>

        <div className="website-container">
          <img src={gitarrenEffekteLogo} alt="" />
          <div>
            <a href="https://www.gitarren-effekte.de/">gitarren-effekte.de</a>
          </div>
        </div>
      </header>
      <footer>
        <a href="https://www.loopstation.net/impressum" rel="nofollow">
          Impressum
        </a>
      </footer>
    </div>
  );
}

export default App;
